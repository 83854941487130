.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 174px 125px;
  height: 100vh;
  background: url("../../assets/img/main.png") center center/cover no-repeat;
  color: #002859;

  .main-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 150%;
    margin-bottom: 0;
    color: #002859;
  }

  .main-subtitle {
    font-weight: 400;
    font-size: 40px;
    line-height: 150%;
    margin-top: 15px;
  }

  .main-descr {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin-top: 15px;
  }
}
