.ant-btn.secondary-btn {
  background: #434343;
  border-color: #434343;
  margin-left: 8px;

  &:hover {
    background: #595959;
    border-color: #595959;
  }

  &:focus {
    background: #595959;
    border-color: #595959;
  }

  &::after {
    display: none;
  }
}
