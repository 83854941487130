.add-policy-statements {
  .statement-form {
    border: none;

    .left-column {
      padding: 24px;
      width: 30%;
      border-right: 1px solid #f0f0f0;
      .select-field {
        width: 100%;
      }
    }
    .right-column {
      padding: 24px 8px 24px 24px;
      background: #fff;
      width: 70%;
      .add-condition-button {
        width: 100%;
      }
    }
    .statement-form-tooltip {
      margin-top: 24px;
    }
  }

  & > div:not(:first-child) {
    margin-bottom: 24px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  div.statement-collapse {
    background: #fff;

    & > div:nth-child(2) > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  div.statement-collapse > div:first-child {
    align-items: center;
  }
}

.sf-descr {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

div.empty-condition-form {
  margin: 80px 0;
}

.add-policy-statements-reset {
  margin-top: 0;
}
