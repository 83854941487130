div.tenant-token-form {
  border: 1px solid #d9d9d9;
  margin-top: 24px;

  .descr > div {
    display: flex;
    align-items: center;
  }

  .descr-input > div > span:last-child {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    .edit-tag:first-child {
      margin-left: 5px;
    }

    & input {
      background-image: none;
      border: none;
      border-radius: 2px;
    }
  }
}
