.add-policy {
  .breadcrumbs {
    background-color: #fff;
    position: fixed;
    z-index: 5;
    top: 63px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #f0f2f5;
  }
  .tabs {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .add-policy-body {
    background-color: #f0f2f5;
    padding: 0;
    .add-policy-card {
      border: 1px solid #d9d9d9;

      & > div:first-child {
        font-weight: 400;
      }

      & > div:nth-child(2) {
        padding: 24px;
        background: #fff;
        border-top: 1px solid #f0f0f0;
        display: flex;
        flex-direction: column;
      }
    }
    .statements-header {
      box-shadow: inset 0px 2px 0px #1890ff;
      margin-bottom: 24px;

      & > div:nth-child(2) {
        display: none;
      }
    }
    .policy-id-input {
      max-width: 672px;
    }
    .policy-id-tooltip {
      color: rgba(0, 0, 0, 0.45);
      margin-left: 4px;
    }
  }
  .no-statements {
    margin-top: 24px;
  }
}
