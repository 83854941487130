.right-column {
  .condition {
    border: none;
    .divider {
      height: 100%;
      margin: 0 16px;
    }
    .title {
      color: rgba(0, 0, 0, 0.45);
    }
    .left-col {
      width: 116px;
    }
  }
  div.condition-collapse {
    margin-bottom: 20px;

    & > div:first-child {
      align-items: center;
      padding: 7px 16px;
    }

    & > div:nth-child(2) > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.add-policy-body {
  background-color: #f0f2f5;
  padding: 24px;
  .general-info {
    border: 1px solid #d9d9d9;
  }
  .policy-id-input {
    max-width: 672px;
  }
  .policy-id-tooltip {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 4px;
  }
}

.add-policy-statements {
  margin-top: 24px;
  .access-type-field {
    margin-top: 8px;
    width: 215px;
  }
  div.condition-attributes {
    width: 365px;
  }
  .checkbox {
    margin-top: 13px;
  }
}

.condition-button {
  margin-right: 8px;
}
