div.App {
  .editor-form {
    margin: 24px;
    .alert {
      margin-bottom: 24px;
    }
    .editor-card {
      border: 1px solid #d9d9d9;
      .reload-button {
        margin-right: 8px;
      }
    }
    .editor {
      border: none;
      height: auto;
      min-height: 150px;
      .jsoneditor {
        border: none;
      }
      .jsoneditor-preview {
        line-height: 22px;
      }
    }
  }
}
