@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppinsregular.ttf") format("truetype"),
    url("../../assets/fonts/Poppins/Poppinsregular.woff") format("woff"),
    url("../../assets/fonts/Poppins/Poppinsregular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppinspoppins-semiboldmedium.ttf")
      format("truetype"),
    url("../../assets/fonts/Poppins/Poppinspoppins-semiboldmedium.woff")
      format("woff"),
    url("../../assets/fonts/Poppins/Poppinspoppins-semiboldmedium.woff2")
      format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppinspoppins-boldsemibold.ttf")
      format("truetype"),
    url("../../assets/fonts/Poppins/Poppinspoppins-boldsemibold.woff")
      format("woff"),
    url("../../assets/fonts/Poppins/Poppinspoppins-boldsemibold.woff2")
      format("woff2");
  font-weight: 600;
  font-style: normal;
}
