@import "./core/fonts.scss";

body {
  font-family: "Poppins";
  background-color: #f1f2f5;
}

ul {
  list-style: none;
}

#root {
  height: 100%;
  background-color: #f0f2f5;
}

.app {
  height: 100%;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.activated-item {
  position: relative;
  padding-left: 16px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #d9d9d9;
    border-radius: 100%;
    transform: translateY(-50%);
  }
}

.activated {
  &::before {
    background-color: #52c41a;
  }
}
