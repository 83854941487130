.policies {
  .policy-statement {
    margin: 24px;
    border: 1px solid #d9d9d9;
    padding: 0;
    .left-column {
      padding: 40px 24px 24px 40px;
      width: 35%;
      .get-label {
        width: 100%;
        display: flex;
        color: #8c8c8c;
        justify-content: space-between;
      }
    }
    .right-column {
      padding: 24px;
      width: calc(65% - 20px);
      .empty-text {
        color: rgba(0, 0, 0, 0.85);
        margin: 8px;
      }
    }
    .effectField {
      font-weight: 500;
      font-size: 16px;

      .icon-allow {
        color: rgb(82, 196, 26);
      }

      .icon-deny {
        color: rgb(255, 77, 79);
      }

      span {
        margin-left: 5px;
      }
    }

    .vertical-divider {
      height: 100%;
    }

    ul {
      li {
        position: relative;
      }

      li::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -9px;
        width: 3px;
        height: 3px;
        background-color: #000;
        border-radius: 100%;
      }
    }
  }
}

.policyList-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 11px 6px;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  span {
    position: relative;
    width: 2px;
    height: 2px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -4px;
      width: 2px;
      height: 2px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -4px;
      width: 2px;
      height: 2px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 100%;
    }
  }
}
