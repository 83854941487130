div.tenant-page {
  padding: 234px 24px 24px 24px;

  .breadcrumbs {
    width: 100%;
    position: fixed;
    top: 63px;
    left: 0;
    background-color: #fff;
    z-index: 2;
  }

  div.header-buttons {
    display: flex;
    .delete-btn {
      margin-right: 8px;
    }
  }

  .plugins-card {
    border: 1px solid #d9d9d9;
  }

  .plugins-card-fields {
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;

    & > span {
      width: 95%;
      margin-right: 8px;
    }

    & > div {
      width: 5%;
    }
  }

  .plugins-card-body {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    padding: 24px;

    .empty-plugins {
      margin: 0 auto;
    }

    .plugin-item {
      max-width: 432px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      transition: all 0.3s;
    }

    .plugin-item:hover {
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }

    .plugin-item > div:first-child > div:first-child > div:first-child {
      cursor: pointer;
    }
  }

  .plugin-item-checked > div:first-child {
    background: #f0f5ff;
  }
}

.spinner-tenant {
  margin-top: 250px;
}

.tenant-name-breadcrumb {
  transition: 0.3s all;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
  }
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;

  .popup-btn {
    margin-right: 8px;
  }
}

.popup-question {
  display: flex;
  align-items: center;

  .anticon {
    width: 18px;
    height: 18px;
    margin-left: 0;

    svg {
      width: 18px;
      height: 18px;
      color: #fff;
      background-color: #faad14;
      border-radius: 100%;
    }
  }

  span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

div.policies-add-card {
  border-top: 2px solid #1890ff;
}

.plugin-drawer > div:nth-child(3) {
  width: 45% !important;
}

.plugin-drawer > div:nth-child(3) > div > div > div:last-child {
  position: relative;
  padding: 0;
}

.plugin-drawer-extra {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    margin-right: 8px;
  }
}

.plugin-drawer-descr {
  padding: 24px;
}

.plugin-drawer-body {
  padding: 24px;
  background: #fafafa;
}

.plugin-drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 16px;
  background-color: #fff;

  .plugin-btn-save {
    margin-left: 8px;
  }
}
