div.audit-log {
  .input-wrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .success {
    &::before {
      background-color: #52c41a;
    }
  }

  .error {
    &::before {
      background-color: #ff4d4f;
    }
  }
}

div.audit-log-table {
  margin-top: 24px;

  & thead > tr > th::before {
    display: none;
  }

  & > div > div > ul li button {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

span.audit-log-input {
  width: 240px;
  height: 40px;
  margin-right: 8px;

  & > span {
    height: 100%;
  }

  & > span > input {
    height: 100%;
  }
}

.audit-log-status {
  position: relative;
  padding-left: 14px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #ddd;
  }
}
