.popup-list {
  margin-bottom: 0;
  padding-left: 12px;
  li {
    position: relative;
  }

  li::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -9px;
    width: 3px;
    height: 3px;
    background-color: #000;
    border-radius: 100%;
  }
}

.tenantList-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 11px 6px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  margin-top: 5px;

  span {
    position: relative;
    width: 2px;
    height: 2px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -4px;
      width: 2px;
      height: 2px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -4px;
      width: 2px;
      height: 2px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 100%;
    }
  }
}
