div.general-card,
div.token-card {
  border: 1px solid #d9d9d9;
}

div.token-card {
  margin-top: 24px;

  .activated-item {
    position: relative;
    padding-left: 16px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      background-color: #d9d9d9;
      border-radius: 100%;
      transform: translateY(-50%);
    }
  }

  .descr > div {
    display: flex;
    align-items: center;
  }

  .descr-input {
    padding-right: 50px;
  }

  .descr-input > div > span:last-child {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    .edit-tag:first-child {
      margin-left: 5px;
    }

    & input {
      background-image: none;
      border: none;
      border-radius: 2px;
    }
  }

  .activated {
    &::before {
      background-color: #52c41a;
    }
  }
}

button.cancel-btn {
  margin-right: 8px;
}

div.account-card {
  margin-top: 24px;
  border: 1px solid #d9d9d9;
}
