.condition {
  .subject-field {
    width: 118px;
  }
  .attribute-field {
    width: 194px;
  }

  div.condition-format {
    width: 173px;
  }
}
