.list-header {
  background-color: #fff;
  padding: 16px 24px 24px 24px;
  margin-top: 63px;

  .header-form {
    flex-wrap: nowrap;
    .header-form-item {
      margin-right: 0;
      > div:first-of-type {
        height: 40px;
        display: flex;
        align-items: center;
      }

      .effect-select {
        min-width: 170px;
      }

      .sort-select {
        min-width: 158px;
      }
    }

    .header-form-item:first-child {
      margin-right: 8px;
    }
  }

  .lh-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
}

.lh-wrapper {
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: space-between;
  margin-top: 16px;

  input {
    font-weight: 400;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
  }

  .lh-search {
    max-width: 740px;
    button {
      width: 40px;
      height: 40px;
    }
  }
}

.lh-sort {
  height: 40px;
}
