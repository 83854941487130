.tenants-table {
  padding: 24px;
}

.table thead > tr {
  & > th > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  th > div > span:first-child {
    flex: none;
  }
}
