.policies {
  min-height: 100vh;
  padding-bottom: 20px;
  background-color: #f0f2f5;

  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
  }
}

.empty-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
